import React from "react";
import {
  LiveIcon,
  Read,
  rectangular,
  ServiceImage,
  SoundIcon,
} from "../../../assets/images";
import CustomButton from "../../../shared/custom-button/custom-button";

const Service = () => {
  return (
    <div
      id="about"
      className="w-full flex flex-col items-center px-8 md:px-32 bg-backgroundHome relative"
    >
      <h1 className="font-montserrat_medium text-title text-3xl md:text-6xl w-11/12  text-center mb-5">
        Vidéo et audio de haute qualité & cours en direct
      </h1>
      <p className="text-text font-montserrat_regular text-center w-11/12 md:w-9/12 mb-5 z-10">
        Apprenez en ligne comme si vous y étiez ! Des cours en direct avec vidéo
        et audio haute qualité pour une expérience immersive et interactive.
        Engagement accru, meilleure rétention et accessibilité pour tous.
      </p>
      <CustomButton
        text={"Visiter les cours"}
        width={"w-min"}
        className="whitespace-nowrap h-12 md:h-14 mb-10"
      />
      <img
        alt="rectagle"
        src={rectangular}
        className="absolute top-44 right-8 hidden lg:block md:right-36 z-0"
      />
      <img
        alt={"service"}
        src={ServiceImage}
        className="w-11/12 object-fill z-10"
      />
      <div className="flex flex-col lg:flex-row w-full justify-center md:justify-between px-4 md:px-24 my-6 space-y-4 lg:space-y-0 flex-wrap md:flex-nowrap">
        <div className="flex justify-between w-full md:w-72 items-center h-20 px-4 bg-white rounded-lg">
          <img
            alt={"sound"}
            src={SoundIcon}
            className="w-14 h-14 object-cover"
          />
          <p className="font-montserrat_medium text-title text-lg md:text-xl">
            Cours audio
          </p>
        </div>
        <div className="flex justify-between w-full md:w-72 items-center h-20 px-4 bg-white rounded-lg">
          <img alt={"live"} src={LiveIcon} className="w-14 h-14 object-cover" />
          <p className="font-montserrat_medium text-title text-lg md:text-xl">
            Cours en direct
          </p>
        </div>
        <div className="flex justify-between w-full md:w-72 items-center h-20 px-4 bg-white rounded-lg">
          <img alt={"read"} src={Read} className="w-14 h-14 object-cover" />
          <p className="font-montserrat_medium text-title text-lg md:text-xl">
            Cours enregistré
          </p>
        </div>
      </div>
    </div>
  );
};

export default Service;
