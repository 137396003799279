import React from "react";
import { Logo } from "../../assets/images";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = () => {
  return (
    <div className="w-full bg-backgroundHome py-8 px-6 lg:px-32">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 lg:gap-16 place-items-center text-center lg:text-left">
        {/* Logo and Social Media */}
        <div className="flex flex-col items-center">
          <img
            alt="logo"
            src={Logo}
            className="w-20 h-20 lg:w-32 lg:h-32 object-cover mb-4"
          />
          <div className="flex space-x-4">
            <a
              href="https://www.facebook.com/profile.php?id=100069589923551"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookOutlinedIcon
                className="text-title"
                style={{ fontSize: 35 }}
              />
            </a>
            <a
              href="https://www.instagram.com/fok_nje7ik?igsh=eGU5ZWI0cjFmdDBi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon className="text-title" style={{ fontSize: 35 }} />
            </a>
            <a
              href="https://youtube.com/@foknje7ik?si=Gx6DuBqwdkokJLUl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTubeIcon className="text-title" style={{ fontSize: 35 }} />
            </a>
          </div>
          <p className="text-title font-montserrat_regular text-xs mt-4">
            ©2024 FOK NJE7IK
          </p>
          <p className="text-title font-montserrat_regular text-xs">
            Numéro : 51 347 528
          </p>
          <p className="text-title font-montserrat_regular text-xs">
            Email : foknjeikacademy@gamil.com
          </p>
        </div>

        {/* Courses Section */}
        <div>
          <p className="font-montserrat_medium text-title text-2xl lg:text-3xl mb-5">
            Cours
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Cours en classroom
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Cours E-learning
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Cours vidéo
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl">
            Cours gratuits
          </p>
        </div>

        {/* Community Section */}
        <div>
          <p className="font-montserrat_medium text-title text-2xl lg:text-3xl mb-5">
            Communauté
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Apprenants
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Partenaires
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Blog
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl">
            Centre d'enseignement
          </p>
        </div>

        {/* Quick Links Section */}
        <div>
          <p className="font-montserrat_medium text-title text-2xl lg:text-3xl mb-5">
            Liens rapides
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Acceuil
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Professional Education
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Cours
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Admissions
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl">
            Témoignages
          </p>
        </div>

        <div>
          <p className="font-montserrat_medium text-title text-2xl lg:text-3xl mb-5">
            Plus
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Termes
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Privacy
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl mb-3">
            Aide
          </p>
          <p className="font-montserrat_regular text-title text-lg lg:text-xl">
            Contact
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
