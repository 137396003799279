import React, { useState } from "react";
import { four, one, three, two } from "../../../assets/images";
import CustomButton from "../../../shared/custom-button/custom-button";

interface ContentItem {
  id: string;
  label: string;
  content: Array<{
    title: string;
    description: string;
    image?: string; // Optional local image
  }>;
}

const contents: ContentItem[] = [
  {
    id: "primaire",
    label: "Primaire",
    content: [
      {
        title: "Primaire Overview",
        description: "Première année primaire, appelée aussi Première",
        image: one,
      },
      {
        title: "Primaire Subjects",
        description: "Deuxiéme année primaire, appelée aussi Deuxiéme",
        image: two,
      },
      {
        title: "Primaire Subjects",
        description: "Troisiéme année primaire, appelée aussi Troisiéme",
        image: three,
      },
      {
        title: "Primaire Subjects",
        description: "Quatriéme année primaire, appelée aussi Quatriéme",
        image: four,
      },
    ],
  },
  {
    id: "college",
    label: "College",
    content: [
      {
        title: "College Overview",
        description: "Septième année primaire, appelée aussi Première",
        image: one,
      },
      {
        title: "College Courses",
        description: "Huitième année primaire, appelée aussi Deuxiéme",
        image: two,
      },
      {
        title: "College Courses",
        description: "Neuvième année primaire, appelée aussi Troisiéme",
        image: three,
      },
    ],
  },
  {
    id: "lycee",
    label: "Lycee",
    content: [
      {
        title: "Lycee Overview",
        description: "Première année secondaire, appelée aussi Première",
        image: one,
      },
      {
        title: "Lycee Curriculum",
        description: "Deuxiéme année secondaire, appelée aussi Deuxiéme",
        image: two,
      },
      {
        title: "Lycee Curriculum",
        description: "Troisiéme année secondaire, appelée aussi Troisiéme",
        image: three,
      },
      {
        title: "Lycee Curriculum",
        description: "Quatriéme année secondaire, appelée aussi Baccalaureat",
        image: four,
      },
    ],
  },
];

const Classes = () => {
  const [activeTab, setActiveTab] = useState<string>(contents[0].id);

  const renderContent = () => {
    const activeContent = contents.find(
      (item) => item.id === activeTab,
    )?.content;

    return (
      <div
        id="free-courses"
        className="w-screen flex md:flex-row flex-col  justify-center flex-wrap gap-6  px-4 md:px-10 lg:px-24 mt-6"
      >
        {activeContent?.map((item, index) => (
          <div
            key={index}
            className="mb-4 bg-white w-full sm:w-2/5 md:w-1/4 lg:w-1/5 h-96 flex flex-col justify-around items-center rounded-2xl shadow-lg"
          >
            <div className="text-center flex flex-col items-center">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="my-3 w-14 h-14 object-cover"
                />
              )}
              <h3 className="font-montserrat_medium text-lg font-bold">
                {item.title}
              </h3>
            </div>
            <p className="font-montserrat_regular text-text w-5/6 text-center">
              {item.description}
            </p>
            <CustomButton
              text={"Détails du cours"}
              width={"w-min"}
              className="bg-white border border-primary text-primary rounded-md hover:bg-primary hover:text-white text-nowrap"
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center bg-backgroundHome py-14">
      <h1 className="text-title text-3xl md:text-4xl lg:text-6xl font-montserrat_medium mb-3 text-center">
        Cours qualifiés pour les élèves
      </h1>
      <p className="font-montserrat_regular text-text mb-7 w-4/5 md:w-2/5 text-center">
        Les cours qualifiés pour les élèves peuvent être une excellente option
        pour acquérir des compétences rapidement.
      </p>
      <div className="flex lg:flex-row flex-col space-x-4 mb-4 space-y-4 lg:space-y-0 ">
        {contents.map((item) => (
          <CustomButton
            key={item.id}
            text={item.label}
            onClick={() => setActiveTab(item.id)}
            className={
              activeTab === item.id
                ? "bg-backgroundHome"
                : "bg-white text-title"
            }
          />
        ))}
      </div>
      <div className="mb-10">{renderContent()}</div>
      <CustomButton text={"Visitez des cours"} />
    </div>
  );
};

export default Classes;
