import React from "react";
import { subscribe } from "../../../assets/images";

const Newsletter = () => {
  return (
    <div
      id="contact"
      className="my-20 w-full h-[50vh] flex bg-backgroundHome items-center justify-center"
    >
      <div
        style={{
          backgroundImage: `url(${subscribe})`,
          backgroundSize: window.innerWidth > 1024 ? "contain" : "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="w-full h-full bg-cover lg:bg-contain bg-center flex justify-center flex-col items-center"
      >
        <h1 className="font-montserrat_medium text-white text-2xl md:text-4xl w-11/12 md:w-5/12 text-center mb-5">
          Abonnez-vous pour obtenir une mise à jour chaque nouveau cours
        </h1>
        <p className="text-white w-10/12 md:w-1/3 font-montserrat_regular text-xs md:text-base text-center mb-6">
          Profitez de votre temps libre pour apprendre de nouvelles compétences.
          Plus de 900 étudiants apprennent quotidiennement avec FOK NJE7IK.
          Abonnez-vous pour découvrir nos nouveaux cours.
        </p>
        <div className="flex items-center flex-col md:flex-row w-full justify-center">
          <input
            placeholder="Entrer votre email"
            className="border-0 bg-white bg-opacity-10 h-12 md:h-14 w-[80vw] md:w-[30vw] rounded-s-lg ps-4 text-white"
          />
          <button className="px-4 py-2 h-12 mt-2 md:mt-0 text-nowrap md:h-14 bg-pink font-montserrat_regular text-white rounded-e-lg rounded-s-lg md:rounded-s-none">
            Abonnez-vous
          </button>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
