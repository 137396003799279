export const classesLevel = [
  { value: "PERMIER_ANNEE_PRIMAIRE", label: "Première Année Primaire" },
  { value: "DEUXIEME_ANNEE_PRIMAIRE", label: "Deuxième Année Primaire" },
  { value: "TROISIEME_ANNEE_PRIMAIRE", label: "Troisième Année Primaire" },
  { value: "QUATRIEME_ANNEE_PRIMAIRE", label: "Quatrième Année Primaire" },
  { value: "CINQUIEME_ANNEE_PRIMAIRE", label: "Cinquième Année Primaire" },
  { value: "SIXIEME_ANNEE_PRIMAIRE", label: "Sixième Année Primaire" },
  { value: "SEPTIEME_ANNEE_DE_BASE", label: "Septième Année de Base" },
  { value: "HUITIEME_ANNEE_DE_BASE", label: "Huitième Année de Base" },
  { value: "NEUVIEME_ANNEE_DE_BASE", label: "Neuvième Année de Base" },
  { value: "PREMIERE_ANNEE_SECONDAIRE", label: "Première Année Secondaire" },
  {
    value: "DEUXIEME_ANNEE_SECONDAIRE_ECONOMIE_GESTION",
    label: "Deuxième Année Secondaire Économie et Gestion",
  },
  {
    value: "DEUXIEME_ANNEE_SECONDAIRE_INFORMATIQUE",
    label: "Deuxième Année Secondaire Informatique",
  },
  {
    value: "DEUXIEME_ANNEE_SECONDAIRE_LETTRES",
    label: "Deuxième Année Secondaire Lettres",
  },
  {
    value: "DEUXIEME_ANNEE_SECONDAIRE_SCIENCES",
    label: "Deuxième Année Secondaire Sciences",
  },
  {
    value: "TROISIEME_ANNEE_SECONDAIRE_ECONOMIE_GESTION",
    label: "Troisième Année Secondaire Économie et Gestion",
  },
  {
    value: "TROISIEME_ANNEE_SECONDAIRE_INFORMATIQUE",
    label: "Troisième Année Secondaire Informatique",
  },
  {
    value: "TROISIEME_ANNEE_SECONDAIRE_LETTRES",
    label: "Troisième Année Secondaire Lettres",
  },
  {
    value: "TROISIEME_ANNEE_SECONDAIRE_SCIENCES",
    label: "Troisième Année Secondaire Sciences",
  },
  {
    value: "TROISIEME_ANNEE_SECONDAIRE_MATH",
    label: "Troisième Année Secondaire Mathématiques",
  },
  {
    value: "TROISIEME_ANNEE_SECONDAIRE_TECHNIQUE",
    label: "Troisième Année Secondaire Technique",
  },
  { value: "BAC_LETTRES", label: "Bac Lettres" },
  { value: "BAC_SCIENCES", label: "Bac Sciences" },
  { value: "BAC_MATH", label: "Bac Mathématiques" },
  { value: "BAC_INFORMATIQUE", label: "Bac Informatique" },
  { value: "BAC_ECONOMIE_GESTION", label: "Bac Économie et Gestion" },
  { value: "BAC_TECHNIQUE", label: "Bac Technique" },
];
export const regions = [
  { value: "TUNIS", label: "Tunis" },
  { value: "ARIANA", label: "Ariana" },
  { value: "BEN_AROUS", label: "Ben Arous" },
  { value: "MANOUBA", label: "Manouba" },
  { value: "NABEUL", label: "Nabeul" },
  { value: "ZAGHOUAN", label: "Zaghouan" },
  { value: "BIZERTE", label: "Bizerte" },
  { value: "BEJA", label: "Béja" },
  { value: "JENDOUBA", label: "Jendouba" },
  { value: "KEF", label: "Kef" },
  { value: "SILIANA", label: "Siliana" },
  { value: "KAIRUAN", label: "Kairouan" },
  { value: "KASSERINE", label: "Kasserine" },
  { value: "SIDI_BOUZID", label: "Sidi Bouzid" },
  { value: "SOUSSE", label: "Sousse" },
  { value: "MONASTIR", label: "Monastir" },
  { value: "MAHDIA", label: "Mahdia" },
  { value: "SFAX", label: "Sfax" },
  { value: "GABES", label: "Gabès" },
  { value: "MEDENINE", label: "Médenine" },
  { value: "TATAOUINE", label: "Tataouine" },
  { value: "GAFSA", label: "Gafsa" },
  { value: "TOZEUR", label: "Tozeur" },
  { value: "KEBILI", label: "Kébili" }
];