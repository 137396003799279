export const columnsAds = [
  { Header: "Titre", accessor: "title" },
  { Header: "education Niveau", accessor: "educationLevel" },
];

export const columnsProf = [
  { Header: "Nom", accessor: "fullName" },
  { Header: "email", accessor: "email" },
  { Header: "phoneNumber", accessor: "phoneNumber" },
];

export const columnsStudent = [
  { Header: "Nom", accessor: "fullName" },
  { Header: "email", accessor: "email" },
  { Header: "phoneNumber", accessor: "phoneNumber" },
];

export const columnsRequests = [
  { Header: "id", accessor: "id" },
  { Header: "Status", accessor: "status" },
  { Header: "Offer", accessor: "teacherOffer.title" },
  { Header: "Sous Titre", accessor: "teacherOffer.subTitle" },
];
export const columnsRequestsStudent = [
  { Header: "id", accessor: "id" },
  { Header: "Status", accessor: "status" },
  { Header: "Offer", accessor: "studentOffer.title" },
  { Header: "Sous Titre", accessor: "studentOffer.subTitle" },
];
export const columnsRequestsCurrentStudent = [
  { Header: "Offer", accessor: "studentOffer.title" },
  { Header: "Sous Titre", accessor: "studentOffer.subTitle" },
  { Header: "Términer le ", accessor: "endDate" },
];
export const columnsRequestsCurrentTeacher = [
  { Header: "Offer", accessor: "teacherOffer.title" },
  { Header: "Sous Titre", accessor: "teacherOffer.subTitle" },
  { Header: "Términer le ", accessor: "endDate" },
];
export const columnsFielManagement = [
  { Header: "Nom du Fichier", accessor: "title" },
  { Header: "Playlist", accessor: "type" },
  { Header: "Telecharge", accessor: "telecharge" },
];
